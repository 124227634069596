"use strict";
// import { DynamicComponent, PropsType, Setter } from "main/types/Representation";
// import classNames from "classnames";
// import React, { ReactNode } from "react";
// import { missingMandatoryProp, missingProps } from "main/utils/errors";
// import { getFilteredProps } from "core/main";
// interface MixinProps extends PropsType {
//     children: (...args: any[]) => ReactNode;
//     selectedDistance: number;
//     setSelectedDistance: Setter<number>;
// }
// function Component({ children, selectedDistance, setSelectedDistance }: MixinProps) {
//     return (
//         <>
//             <div className="rt-radius-buttons">
//                 <button
//                     className={classNames("rt-radius-button", {
//                         "rt-radius-button--selected": selectedDistance === 5,
//                     })}
//                     onClick={() => setSelectedDistance(5)}
//                 >
//                     5 Km
//                 </button>
//                 <button
//                     className={classNames("rt-radius-button", {
//                         "rt-radius-button--selected": selectedDistance === 10,
//                     })}
//                     onClick={() => setSelectedDistance(10)}
//                 >
//                     10 Km
//                 </button>
//                 <button
//                     className={classNames("rt-radius-button", {
//                         "rt-radius-button--selected": selectedDistance === 50,
//                     })}
//                     onClick={() => setSelectedDistance(50)}
//                 >
//                     50 Km
//                 </button>
//             </div>
//             {children}
//         </>
//     );
// }
// export const dynamicComponent: DynamicComponent<MixinProps> = {
//     Component,
//     checkExpectedProps: (props) => {
//         const componentName = "RadiusBar";
//         if (!props) throw new Error(missingProps(componentName));
//         if (!props.selectedDistance) {
//             throw new Error(missingMandatoryProp(componentName, "selectedDistance"));
//         }
//         if (!props.setSelectedDistance) {
//             throw new Error(missingMandatoryProp(componentName, "setSelectedDistance"));
//         }
//     },
//     filterProps: (props) => {
//         if (!props) throw new Error(missingProps("RadiusBar"));
//         const neededProps = ["selectedDistance", "setSelectedDistance"];
//         return getFilteredProps(props, ([propName, _]) => neededProps.some((p) => p === propName));
//     }
// };
